<template>
  <v-container fluid>
    <!---BOC:[breadcrumb]-->
    <ABreadcrumb :items="breadcrumb.items" />
    <!--EOC-->
    <BreadHeader :title="`New ${model.name.singular}`" />
    <!---BOC:[form]-->
    <BreadForm action="add" :api="api" :model="model" />
    <!--EOC-->
    <!---BOC:[note]-->
    <ADevNote
      :text="[
        'Validation',
        ['✗ Validate authority', '✗ Validate ownership'],
        'Layout',
        ['✓ Show breadcrumbs', '✓ Show title', '✓ Replace model item name'],
        'Read',
        ['✓ Run API Read', '✓ Show API Read Loading', '✓ Show API Read Error'],
        'Form',
        [
          '✓ Show Form',
          '✓ Get Form Field',
          '✓ Get Form Rules',
          '✓ Get Form Data',
        ],
        'Action',
        [
          '✓ Form Button isLoading',
          '✓ Run Action API',
          '✓ Show Action API Loading',
          '✓ Show Action API Error',
        ],
        'Parent',
        [
          '✓ Fetch data',
          '✓ Show loading',
          '✗ Show error',
          '✓ Show null',
          '✗ Select default value',
        ],
      ]"
    />
    <!--EOC-->
  </v-container>
</template>

<script>
//BOC:[api]
import Api from "@/objects/api";
//EOC
//BOC:[breadcrumb]
import Breadcrumb from "@/objects/breadcrumb";
//EOC
//BOC:[model]
import Model from "@/objects/model";
//EOC
//BOC:[service]
import Service from "@/objects/service";
//EOC
import { mapState } from "vuex";
export default {
  components: {
    //
  },
  computed: mapState({
    services: (state) => state.service.data.services,
  }),
  props: {
    //
  },
  data: () => ({
    //BOC:[api]
    api: new Api(),
    //EOC
    //BOC:[breadcrumbs]
    breadcrumb: new Breadcrumb(),
    //EOC
    //BOC:[model]
    model: new Model(),
    //EOC
    //BOC:[service]
    service: new Service(),
    //EOC
  }),
  created() {
    //BOC:[model]
    this.model.getByKey(this.$route.params.modelKey);
    //EOC
    //BOC:[service]
    if(this.model.serviceKey) this.service.set("key",this.model.serviceKey);
    //EOC
    //BOC:[breadcrumbs]
    this.breadcrumb.addForOperationBrowse({ model: this.model, services: this.services });
    //EOC
    //BOC:[api]
    this.api.setMethod(`POST`);
    this.api.setUrl(
      `${this.$service[this.service.key]}/v1/en/console/model/${this.model.key}/add`
    );
    //
    if(this.model.add.customApiPath) {
      this.api.setUrl(
        `${this.$service[this.service.key]}${this.model.add.customApiPath}`
      );
    }
    //
    this.api.setCallbackCompleted(() => {
      this.$router.push({
        name: "PageServiceModelBrowse",
        params: {
          serviceKey: this.model.serviceKey,
          modelKey: this.model.key,
        },
      });
      this.$store.dispatch("showMessage", "Created successfully.");
    });
    //EOC
  },
  mounted() {
    //
  },
  methods: {
    //
  },
};
</script>