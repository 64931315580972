var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('ABreadcrumb',{attrs:{"items":_vm.breadcrumb.items}}),_c('BreadHeader',{attrs:{"title":("New " + (_vm.model.name.singular))}}),_c('BreadForm',{attrs:{"action":"add","api":_vm.api,"model":_vm.model}}),_c('ADevNote',{attrs:{"text":[
        'Validation',
        ['✗ Validate authority', '✗ Validate ownership'],
        'Layout',
        ['✓ Show breadcrumbs', '✓ Show title', '✓ Replace model item name'],
        'Read',
        ['✓ Run API Read', '✓ Show API Read Loading', '✓ Show API Read Error'],
        'Form',
        [
          '✓ Show Form',
          '✓ Get Form Field',
          '✓ Get Form Rules',
          '✓ Get Form Data' ],
        'Action',
        [
          '✓ Form Button isLoading',
          '✓ Run Action API',
          '✓ Show Action API Loading',
          '✓ Show Action API Error' ],
        'Parent',
        [
          '✓ Fetch data',
          '✓ Show loading',
          '✗ Show error',
          '✓ Show null',
          '✗ Select default value' ] ]}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }